// Styling for the team section
@use "sass:color";
@use "../base/variables" as vars;

.team-member {
  margin-bottom: 50px;
  text-align: center;
  
  img {
    width: 225px;
    height: 225px;
    border: 7px solid color.adjust(vars.$black, $alpha: -0.9);
  }
  
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  
  p {
    margin-top: 0;
  }
}
