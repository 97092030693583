// Global styling for this template
@use "sass:color";
@use "./variables" as vars;
@use "./_mixins" as mixins;

body {
  overflow-x: hidden;
  @include mixins.body-font;
}

p {
  line-height: 1.75;
}

a {
  color: vars.$primary;
  &:hover {
    color: color.adjust(vars.$primary, $lightness: -10%);
  }
}

.text-primary {
  color: vars.$primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include mixins.heading-font;
}

.page-section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include mixins.serif-font;
  }
}

@media(min-width:768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: vars.$primary;
  text-shadow: none;
}

::selection {
  background: vars.$primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}
