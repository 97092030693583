// Styling for the masthead
@use "../base/_mixins" as mixins;

header.masthead {
  text-align: center;
  color: white;
  background-image: url("../img/header-bg.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  
  .header-text {
    padding-top: 150px;
    padding-bottom: 100px;
    
    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include mixins.serif-font;
    }
    
    .heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include mixins.heading-font;
    }
  }
}

@media (min-width: 768px) {
  header.masthead {
    .header-text {
      padding-top: 300px;
      padding-bottom: 200px;
      
      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include mixins.serif-font;
      }
      
      .heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include mixins.heading-font;
      }
    }
  }
}
