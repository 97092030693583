// Styling for the contact section
@use "../base/variables" as vars;
@use "../base/_mixins" as mixins;

section#contact {
  background-color: vars.$gray-900;
  background-image: url('../img/map-image.png');
  background-repeat: no-repeat;
  background-position: center;
  
  .section-heading {
    color: vars.$white;
  }
  
  .form-group {
    margin-bottom: 25px;
    
    input,
    textarea {
      padding: 20px;
    }
    
    input.form-control {
      height: auto;
    }
    
    textarea.form-control {
      height: 248px;
    }
  }
  
  .form-control:focus {
    border-color: vars.$primary;
    box-shadow: none;
  }
  
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: vars.$gray-400;
    @include mixins.heading-font;
  }
  
  :-moz-placeholder {
    font-weight: 700;
    color: vars.$gray-400;
    @include mixins.heading-font;
  }
  
  ::-moz-placeholder {
    font-weight: 700;
    color: vars.$gray-400;
    @include mixins.heading-font;
  }
  
  :-ms-input-placeholder {
    font-weight: 700;
    color: vars.$gray-400;
    @include mixins.heading-font;
  }
}
